<template>
  <v-card outlined class="pa-4">
    <v-card-title class="font-weight-bold">Sales Component</v-card-title>
    <StackedBar :chartData="chartData" :options="options"></StackedBar>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "BaseAndImpact",
  data: () => ({}),
  components: {
    StackedBar: () => import("@/components/chart/StackedBar"),
  },
  computed: {
    chartData() {
      return this.$store.getters.summaryBarChartData;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    options() {
      const gridlines_color = this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: fontColor,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              type: "time",
              time: {
                parser: (value) => {
                  return moment(value, "YYYYMMDD");
                },
                unit: "month",
                unitStepSize: 1,
                displayFormats: {
                  month: "YYYY/MM/DD",
                },
              },
              stacked: true,
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              ticks: {
                fontColor: fontColor,
              },
              scaleLabel: {
                display: true,
                fontColor: fontColor,
                labelString: this.$t("date"),
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              ticks: {
                // min: -1,
                // max: 1,
                callback: (value) => {
                  return this.formatPercentage(value / 100);
                },
                beginAtZero: true,
                fontColor: fontColor,
              },
              scaleLabel: {
                display: true,
                fontColor: fontColor,
                labelString: this.$t("percentage"),
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return `${tooltipItem.yLabel}%`;
            },
          },
        },
      };
    },
  },
  methods: {
    formatPercentage(value) {
      return Number(value).toLocaleString(undefined, { style: "percent" });
    },
  },
};
</script>

<style scoped></style>
